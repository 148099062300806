import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '~components/layouts/Default';
import SchemaPodcastEpisode from '~components/layouts/schemas/SchemaPodcastEpisode';
import Breadcrumb from '~components/common/Breadcrumb';
import Container from '~components/common/Container';
import P from '~components/typography/P';
import SvgSpotify from '~components/common/svgs/SvgSpotify';
import SvgItunes from '~components/common/svgs/SvgItunes';
import SvgGooglePodcast from '~components/common/svgs/SvgGooglePodcast';
import parse from 'html-react-parser';
import { convertDate } from '../functions/formating/convertDate';
import Button from '~components/common/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Episodes = ({ data, location, pageContext }) => {
  let { titel, titel_seo, einleitung_seo, einleitung, folgennummer, datum, cockpitModified, slug_slug, thema, spotify, itunes, googlepodcast, text, remoteTitelbild, arbeitsmittel } = data.allPodcastFolgen.nodes[0];

  const hasFeedCastLink = data?.feedLetsCast?.enclosure?.url;

  return (
    <Layout
      location={location}
      canonical={`${data.site.siteMetadata.siteUrl}/podcast/folgen/${folgennummer}/${slug_slug}/`}
      title={titel_seo}
      desc={einleitung_seo}
      imageFacebook={data.allPodcastFolgen.nodes[0].remoteTitelbild.childImageSharp.facebook.src}
      imageFacebookWidth={data.allPodcastFolgen.nodes[0].remoteTitelbild.childImageSharp.facebook.width}
      imageFacebookHeight={data.allPodcastFolgen.nodes[0].remoteTitelbild.childImageSharp.facebook.height}
      imageTwitter={data.allPodcastFolgen.nodes[0].remoteTitelbild.childImageSharp.twitter.src}
      active="Podcast"
      noIndex={pageContext.canonical ? true : false}
    >
      <SchemaPodcastEpisode
        url={`${data.site.siteMetadata.siteUrl}/podcast/folgen/${folgennummer}/${slug_slug}/`}
        title={titel}
        published={datum}
        modified={cockpitModified > datum ? cockpitModified : datum}
        description={einleitung}
        medialink={hasFeedCastLink ? hasFeedCastLink : null}
      />

      <Container noMargin className="my-8">
        <Breadcrumb pages={[{ title: 'Wissen', link: '/wissen/' }, { title: 'Podcast', link: '/podcast/' }, { title: 'Folgen', link: '/podcast/folgen/' }, { title: titel }]} className="mb-4 flex justify-center" />
        <div className="mx-auto max-w-3xl">
          <GatsbyImage image={getImage(remoteTitelbild)} className="mb-6 rounded-xl duration-300" alt={titel_seo} />
          <div className="mb-2 text-base text-gray-700">
            Folge: {folgennummer} - {convertDate(datum)} - Thema: <span>{thema.display}</span>
          </div>
          <div className="prose prose-lg mb-3">
            <h1>{titel}</h1>
            <p>{einleitung}</p>
          </div>

          {hasFeedCastLink && (
            <div className="mb-4 mt-8">
              <div className="mb-4">
                <audio src={hasFeedCastLink} controls={true} className="w-full" />
              </div>
            </div>
          )}
          {spotify || itunes || googlepodcast ? (
            <div className="mmd:grid-cols-3 mb-6 grid grid-cols-1 gap-3">
              {spotify && (
                <a
                  href={spotify}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center rounded-xl border border-gray-300 p-3 text-white shadow transition duration-300 hover:shadow-none"
                  style={{ backgroundColor: '#1DB954' }}
                >
                  <SvgSpotify className="mr-3 inline h-6 w-6" /> Spotify
                </a>
              )}
              {itunes && (
                <a href={itunes} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center rounded-xl border border-gray-300 p-3 shadow transition duration-300 hover:shadow-none">
                  <SvgItunes className="mr-3 inline h-6 w-6" /> iTunes
                </a>
              )}
              {googlepodcast && (
                <a
                  href={googlepodcast}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center rounded-xl border border-gray-300 p-3 text-white shadow transition duration-300 hover:shadow-none"
                  style={{ backgroundColor: '#4285F4' }}
                >
                  <SvgGooglePodcast className="mr-3 inline h-6 w-6" /> Google Podcast
                </a>
              )}
            </div>
          ) : (
            ''
          )}

          {Array.isArray(arbeitsmittel) && arbeitsmittel.length > 0 && arbeitsmittel.some((entry) => entry.value) && (
            <div className="my-4 px-3 py-3 text-brand-gray">
              <p className="font-display font-bold leading-relaxed">Arbeitsmittel</p>
              <div className="grid gap-3 pt-2">
                {arbeitsmittel.map((download, i) => {
                  if (download.value !== null) {
                    return <Button text={download.value.title} href={`https://cms.entspannungshelden.de/storage/uploads${download.value.path}`} download target="_blank" key={download.value.title} />;
                  }
                  return <React.Fragment key={i}></React.Fragment>;
                })}
              </div>
            </div>
          )}
          <div className="prose prose-xl mb-3">{parse(text.replace(/\/storage\/uploads\//g, 'https://cms.entspannungshelden.de/storage/uploads/'))}</div>
        </div>
      </Container>
    </Layout>
  );
};

export default Episodes;

export const query = graphql`
  query ($id: String, $castTitel: String) {
    allPodcastFolgen(filter: { id: { eq: $id }, public: { eq: true } }) {
      nodes {
        datum
        einleitung
        einleitung_seo
        folgennummer
        id
        slug_slug
        spotify
        itunes
        googlepodcast
        text
        titel
        thema {
          display
        }
        titel_seo
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData
            facebook: fixed(height: 630, width: 1200) {
              src
              width
              height
            }
            twitter: fixed(height: 438, width: 876) {
              src
            }
          }
        }
        arbeitsmittel {
          value {
            path
            title
            mime
            description
            size
            image
            video
            audio
            archive
            document
            code
            created
            modified
            _by
            folder
            _id
          }
        }
      }
    }
    allPodcastThemen {
      nodes {
        farbe
        thema
        slug_slug
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    feedLetsCast(title: { eq: $castTitel }) {
      enclosure {
        type
        url
      }
      title
    }
  }
`;
