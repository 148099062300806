import React from 'react';

const SvgGooglePodcast = ({ className, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={fill ? fill : 'currentColor'} className={className}>
      <path d="M2.5 9.32v1.36a1.25 1.25 0 01-2.5 0V9.32a1.25 1.25 0 012.5 0zM17.5 9.35v-.04c0-.69.56-1.25 1.25-1.25S20 8.63 20 9.32V10.72c-.02.67-.57 1.21-1.25 1.21s-1.23-.54-1.25-1.21V9.35zM6.82 13.41v1.36a1.25 1.25 0 01-2.5 0v-1.36a1.25 1.25 0 012.5 0zm0-8.18v4.46a1.25 1.25 0 01-2.5 0V5.23a1.25 1.25 0 012.5 0zM13.18 6.59a1.25 1.25 0 002.5 0V5.23a1.25 1.25 0 00-2.5 0v1.36zM8.75 2.61a1.25 1.25 0 002.5 0V1.25a1.25 1.25 0 00-2.5 0v1.36zm0 14.78a1.25 1.25 0 012.5 0v1.36a1.25 1.25 0 01-2.5 0v-1.36zM13.18 10.34a1.25 1.25 0 012.5 0v4.43a1.25 1.25 0 01-2.5 0v-4.43zM11.25 6.36v7.27a1.25 1.25 0 01-2.5 0V6.36a1.25 1.25 0 012.5 0z" />
    </svg>
  );
};

export default SvgGooglePodcast;
